// Import Styles
import '../sass/styles.scss';

// Import Plugins
import 'bootstrap';

// Import Sliders
import './slider/testimonial-slider';

(function () { 

    $(document).ready(function() 
    {
        // Hamburger Menu
        $('.hamburger').on('click', function(e) 
        {
            e.preventDefault();

            $(this).toggleClass('is-active');
            $('.js-header__navigation').toggleClass('is-active');
        });

        // Double Tap to Go
        if (window.matchMedia('(max-width: 768px)').matches) {
            var previousTarget = null;

            $('.menu-item-has-children > a').on('click', function(e) 
            {
                $('.sub-menu').removeClass('js-open');

                if (this === previousTarget) {
                    // Do nothing...
                } else {
                    e.preventDefault();
                    previousTarget = this;
                }

                $(this).next('.sub-menu').toggleClass('js-open');
            });
        }

        // Portfolio Tabs
        if ( $('#js-tab-select') ) {
            $('#js-tab-select').on('change', function() {
                var selectedTabID = $(this).val() + '-tab';
                var selectedTabContentID = $(this).val();
                
                // Deactivate all tabs and tab contents
                $('.nav-tabs .nav-link').removeClass('active');
                $('.tab-content .tab-pane').removeClass('show active');

                // Activate selected tab and tab content
                $('#' + selectedTabID).addClass('active');
                $('#' + selectedTabContentID).addClass('show active');
            });
        }
    });

    $(window).scroll(function() 
    {
        
    });

})();