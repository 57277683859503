import Glide from '@glidejs/glide'

if ( $('.js-testimonial-slider').length )
{
    new Glide('.js-testimonial-slider', {
        type: 'carousel',
        perView: 1,
        gap: 30,
        autoplay: 7000,    
    }).mount();
}